import React from 'react';
import 'aos/dist/aos.css';
import Link from 'next/link';

export function RestrictedLayoutWidth({ children }) {
  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 md:px-8 lg:px-12 xl:px-16">
      {children}
    </div>
  );
}

export function ResponsiveGridFromOneToTwo({ LeftSide, RightSide }) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div>{LeftSide}</div>
      <div>{RightSide}</div>
    </div>
  );
}

export function StandardHeader({ HeaderText }) {
  return (
    <div className="text-h1 text-tertiary">
      {HeaderText}
    </div>
  );
}

export function StandardPinkUpperCaseAboveTheFoldHeader({ HeaderText }) {
  return (
    <h2 className="text-h2 text-text-primary">
      {HeaderText}
    </h2>
  );
}

export function StandardHeaderWhite({ HeaderText }) {
  return <div className="text-h2 text-text-primary">{HeaderText}</div>;
}

export function ArrowIcon() {
  return (
    <div className={`text-text-primary pl-1`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 7l5 5m0 0l-5 5m5-5H6"
        />
      </svg>
    </div>
  );
}
export function PDFIcon() {
  return (
    <div className={`text-text-primary pl-1`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    </div>
  );
}

export function HeaderButton({
  headerButtonText = 'Default',
  linkTo = '#',
  newTab = false,
  buttonColor,
  icon = <ArrowIcon />,
}) {
  return (
    <Link
      href={linkTo}
      className="flex"
      target={newTab ? '_blank' : ''}
      legacyBehavior>
      <div
        className={`${
          buttonColor == 'blue' ? 'bg-figma-secondaryone' : 'bg-figma-primary'
        } px-16 py-3 items-center justify-center text-md text-text-primary flex rounded-full`}
      >
        <div className="flex">
          <div className={`text-text-primary`}>{headerButtonText}</div>
          {icon}
        </div>
      </div>
    </Link>
  );
}

export function SpecialHeader({ HeaderText, HeaderTextPink, inline = false }) {
  return (
    <div>
      {inline ? (
        <div className="text-4xl font-bold flex space-x-3">
          <div className="text-text-primary">{HeaderText}</div>

          <div className="text-tertiary">{HeaderTextPink}</div>
        </div>
      ) : (
        <div className="text-4xl font-bold">
          <div className="text-text-primary">{HeaderText}</div>

          <div className="text-tertiary">{HeaderTextPink}</div>
        </div>
      )}
    </div>
  );
}

export function StandardParagraphPurpleText({ ParagraphText }) {
  return (
    <div className="text-tertiary text-lg">
      {ParagraphText.map(paragraphs => (
        <div className="mt-16 mb-4" key={paragraphs}>
          {paragraphs}
        </div>
      ))}
    </div>
  );
}

export function StandardParagraphWhiteText({ ParagraphText }) {
  return (
    <div className="text-text-primary text-lg">
      {ParagraphText.map(paragraphs => (
        <div key={Math.floor(Math.random() * 1000) + 1} className="mt-4 mb-4">
          {paragraphs}
        </div>
      ))}
    </div>
  );
}

export function ButtonWhite({ Text, LinkTo }) {
  return (
    <div className="inline-flex items-center justify-center text-md mt-4">
      <Link
        href={LinkTo}
        className="rounded-l-md py-2 px-4 transition ease-in-out duration-500 text-text-primary bg-white hover:text-text-secondary hover:bg-ilder-black border border-r-2"
        legacyBehavior>
        {Text}
      </Link>
      <div className="rounded-r-md py-2 px-2 pr-3 text-text-primary bg-white border">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
      </div>
    </div>
  );
}

export function ButtonWhiteProjects({ Text, LinkTo }) {
  return (
    <div className="inline-flex items-center justify-center text-md mt-4">
      <Link
        href={LinkTo}
        className="rounded-l-md py-2 px-4 transition ease-in-out duration-500 text-text-primary bg-white hover:text-text-secondary hover:bg-ilder-black border border-r-2"
        target="_blank"
        rel="noreferrer"
        legacyBehavior>
        {Text}
      </Link>
      <div className="rounded-r-md py-2 px-2 pr-3 text-text-primary bg-white border">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-7 w-7"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
      </div>
    </div>
  );
}

function DefaultButton({ link, text }) {
  return (
    <Link href={link} legacyBehavior>
      <span className="inline-block mt-2 mb-4 hover:cursor-pointer hover:bg-secondary-hover px-4 py-2 bg-secondary rounded-full text-secondary-foreground">
        {text}
      </span>
    </Link>
  );
}

function PinkBorderButton({ link, text }) {
  return (
    <Link href={link} legacyBehavior>
      <span className="inline-block mt-2 mb-4 hover:cursor-pointer hover:bg-tertiary-hover hover:text-tertiary-foreground px-5 py-3 bg-transparent border-2 border-tertiary rounded-full text-small text-text-primary">
        {text}
      </span>
    </Link>
  );
}

export function PinkTag({ tag }) {
  return (
    <span className="text-small text-secondary-foreground rounded-full bg-secondary px-4 py-1">
      {tag}
    </span>
  );
}

function PinkBorderDarkTextButton({ link, text }) {
  return (
    <Link href={link} legacyBehavior>
      <span className="hover:cursor-pointer mt-4 px-5 py-3 bg-transparent border-2 border-tertiary rounded-full text-text-primary inline-block">
        {text}
      </span>
    </Link>
  );
}

export function ButtonLink({ style, button, link = '/', text = '' }) {
  button?.buttonText ? (text = button.buttonText) : '';
  button?.title ? (text = button.title) : '';

  button?.dynamicPage?.slug ? (link = button.dynamicPage.slug) : '';
  button?.link ? (link = button.link) : '';

  switch (style) {
    case 'pinkborder':
      return <PinkBorderButton text={text} link={link} />;
    case 'pinkborderdarktext':
      return <PinkBorderDarkTextButton text={text} link={link} />;
    case 'anotherstyle':
      return <DefaultButton text={text} link={link} />;
    default:
      return <DefaultButton text={text} link={link} />;
  }
}

export function GradientWrapper({ children }) {
  return (
    <div>
      {children}
    </div>
  );
}

export function StandardWrapper7xl({ children }) {
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8 py-16">{children}</div>
  );
}
