import React from 'react';
import Link from 'next/link';
import Obfuscate from 'react-obfuscate';
import { GradientWrapper } from 'components/LayoutUtilities';

const LogoAndContact = ({ navigation }) => {
  return (
    <div className="space-y-8 xl:col-span-1">
      <Link href={'/'} legacyBehavior>
        <img
          className="h-10 cursor-pointer"
          src={navigation?.navigationLogo?.url}
          alt="Ilder AS"
        />
      </Link>
      <h5 className="text-h5 text-text-primary">
        Innovasjon møter <span className="text-brand-pink">instinkt</span>
      </h5>
      <ul role="list" className=" space-y-2 text-text-secondary">
        <li className="text-text-link underline hover:no-underline">
          <Obfuscate
            email={navigation?.contact?.email}
            headers={{
              subject: 'Contact via ilder.no',
            }}
          />
        </li>
        <li>{navigation?.contact?.phone}</li>
        <li>{navigation?.contact?.orgNr}</li>
      </ul>
    </div>
  );
};

const Locations = ({ navigation }) => {
  return (
    <div>
    <h6 className="text-h6 text-text-primary">
        {navigation?.location?.title}
    </h6>
      <ul role="list" className="mt-4 space-y-6">
        {navigation?.location?.text?.map(textBlock => (
          <li
            className={`text-text-secondary mt-4 space-y-4`}
            key={textBlock}
          >
            {textBlock}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Legal = ({ navigation }) => {
  return (
    <div>
    <h6 className="text-h6 text-text-primary">
      Juridisk
    </h6>
      <ul role="list" className="mt-4 space-y-6">
        <li className="text-text-link mt-4 space-y-4 underline hover:no-underline">
          <Link
            href={'/privacy'}
            className={`text-link mt-4 space-y-4 underline hover:no-underline`}
          >
            Personvern
          </Link>
        </li>{' '}
      </ul>
    </div>
  );
};

const Links = ({ navigation }) => {
  return (
    <div>
      <h6 className="text-h6 text-text-primary">
        {navigation?.navigation?.title}
      </h6>
      <ul role="list" className="mt-4 space-y-4">
        {navigation?.dynamicPages.map(nav => (
          <li
            key={nav.id}
            className={`text-text-link mt-4 space-y-4 underline hover:no-underline`}
          >
            <Link href={'/' + nav.slug} legacyBehavior>
              {nav.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const SocialMedia = ({ navigation }) => {
  return (
    <div className="mt-12 md:mt-0">
    <h6 className="text-h6 text-text-primary">
      {navigation?.socials?.title}
    </h6>
      <div className="mt-4 space-y-4"></div>
      <ul role="list" className="mt-4 flex flex-row">
        <li>
          <Link href="https://www.facebook.com/ilder.no" legacyBehavior className="cursor-pointer" target="_blank" rel="noopener noreferrer">
            <svg
              fill="currentColor"
              viewBox="0 0 24 24"
              className="h-12 text-tertiary hover:text-tertiary-hover cursor-pointer"
            >
              <title>Facebook icon</title>
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          </Link>
        </li>
        <li>
          <Link href="https://www.linkedin.com/company/ilder" legacyBehavior className="cursor-pointer" target="_blank" rel="noopener noreferrer">
            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              className="h-12 text-tertiary hover:text-tertiary-hover ml-4 cursor-pointer"
            >
              <title>Linkedin icon</title>
              <path
                fillRule="evenodd"
                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                clipRule="evenodd"
              />
            </svg>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default function Footer({ navigation }) {
  return (
    <footer className="bg-background mt-36 text-primary-foreground" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-6 md:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            {/* Logo og kontaktinformasjon */}
            <LogoAndContact navigation={navigation} />
            {/* Navigasjon */}
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              {/* Lokasjoner */}
              <div className="md:grid md:grid-cols-2 md:gap-8 py-4">
                <Links navigation={navigation} />

                <Legal navigation={navigation} />
              </div>
              {/* Navigasjon */}
              <div className="md:grid md:grid-cols-2 md:gap-8 py-4 ">
                {/* Sosiale medier */}
                <Locations navigation={navigation} />

                <SocialMedia navigation={navigation} />
              </div>
            </div>
          </div>
        </div>
    </footer>
  );
}
