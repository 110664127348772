import React, { useContext, useEffect, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { Analytics } from '@vercel/analytics/react';
import dynamic from 'next/dynamic';
import { TrackContext } from 'components/track';

const CrispWithNoSSR = dynamic(() => import('../components/crisp'), {
  ssr: false,
});

const Layout = (props) => {
  const track = useContext(TrackContext);

  let title = `ilder | ${
    props.metaData?.title || props.pageTitle || 'Protopage'
  }`;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{title}</title>
        {props.schema ? (
          // eslint-disable-next-line @next/next/inline-script-id
          <Script
            key={`schemaJSON-${props.id}`}
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(props.schema),
            }}
          />
        ) : (
          ''
        )}

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#e53489" />
        <meta name="msapplication-TileColor" content="#603cba" />
        <meta name="theme-color" content="#ffffff"></meta>
        <meta
          name="description"
          content={props.metaData?.description}
          key="description"
        />
        <meta
          property="og:title"
          content={props.metaData?.title}
          key="ogtitle"
        />
        <meta
          property="og:description"
          content={props.metaData?.description}
          key="ogdescription"
        />

        <meta
          property="og:image"
          content={
            props.metaData?.image ? props.metaData.image.url : 'OGDefault'
          }
          key="ogimage"
        />
      </Head>
      <main className="font-ilder bg-background">
        <Script
          id="gtag"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5N89ZHQ');`,
          }}
        ></Script>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5N89ZHQ"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
        {props.children}
        {(track && process.env.NODE_ENV === 'production') ? (
          <CrispWithNoSSR />
        ) : (
          ''
        )}
        <Analytics />
      </main>
    </>
  );
};

export default Layout;
