import React, { useState, useEffect } from 'react';
import Link from 'next/link';

const Header = ({
  navigation,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="w-full bg-background text-text-primary">
      <div className="mx-auto z-20">
        <div className="max-w-7xl mx-auto">
          <div className="flex  h-24 z-20">
            <div className="flex relative px-4 sm:px-6 md:px-6 w-full">
              <div className="flex-shrink-0 flex items-center ">
                <Link href="/" legacyBehavior>
                  <figure className="cursor-pointer">
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src={navigation?.navigationLogo?.url}
                      alt="Logo"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src={navigation?.navigationLogo?.url}
                      alt="Logo"
                    />
                  </figure>
                </Link>
              </div>
              <div className="flex absolute right-0 mr-2 top-1/4 items-center md:hidden">
                <button
                  type="button"
                  onClick={toggleMenu}
                  className={`inline-flex items-center justify-center p-2 rounded-md text-text-link hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ring`}
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>

                  <svg
                    className="block w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    className="hidden w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>{' '}
              </div>
            </div>
            <div className="flex items-center md:px-2">
              <div className="hidden md:pl-4 md:flex md:items-center basis-auto">
                {navigation?.dynamicPages?.map(nav => (
                  <Link href={'/' + nav.slug} key={nav.id} legacyBehavior>
                    <span
                      className={`whitespace-nowrap cursor-pointer px-3 text-text-link underline hover:no-underline inline-flex items-center pt-1 transition ease-in-out duration-500`}
                    >
                      {nav.title}
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {menuOpen ? (
        <div className={`md:hidden`} id="mobile-menu">
          <div
            className={`pt-2 pb-3 space-y-1 relative z-20`}
          >
            {/*TODO: Simple solution for now: changed Link to <Link> to make it so the navbar closes. It should probably be remade. */}
            {navigation?.dynamicPages?.map(nav => (
              <Link href={'/' + nav.slug} key={nav.id} legacyBehavior>
                <span
                  className={`cursor-pointer border-transparent text-text-link flex underline hover:no-underline block pl-3 py-2 border-l-4 sm:pl-5`}
                >
                  <div className="pt-2">{nav.title}</div>
                </span>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </nav>
  );
};

export default Header;
